import { GetStaticProps } from 'next'
import React from 'react'

import { SEO } from '../components/elements/SEO'
import { IndexTemplate } from '../components/templates/IndexTemplate'
import { IndexDocument, IndexQuery } from '../generated/graphql'
import { initializeApollo } from '../utils/ApolloClient'

type Props = {
  products: IndexQuery['featuredProducts']
}

const IndexPage: React.FC<Props> = ({ products }) => {
  if (!products) return null
  return (
    <>
      <SEO
        title="RYDE PASS | 二次交通をもっとわかりやすく、もっと簡単に"
        description="全国の様々な移動手段のフリーパスや1日乗車券等の利用をわかりやすく、簡単にするモビリティプットフォームです"
        openGraph={{
          images: [
            {
              url: `${process.env.HOST}/logo/RYDE_PASS.jpg`,
            },
          ],
          type: 'website',
          url: 'https://pass.ryde-go.com/',
          site_name: 'RYDE PASS | 二次交通をもっとわかりやすく、もっと簡単に',
          locale: 'ja_JP',
        }}
      />
      <IndexTemplate products={products} />
    </>
  )
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const apolloClient = initializeApollo()
  const { data, error } = await apolloClient.query<IndexQuery>({
    query: IndexDocument,
  })

  if (error) {
    throw error
  }

  return {
    props: {
      products: data.featuredProducts,
    },
    revalidate: 3600, // 1時間
  }
}

export default IndexPage
